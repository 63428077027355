import * as JSZip from 'jszip';
import {saveAs} from 'file-saver';

/**
 * @param secret
 */
function getAu(secret) {
  const contents = `"system_id","type","command_line","Max_Time_Allowed","time_limit_action","file_name","max_score","mastery_score","system_vendor","core_vendor","web_launch","au_password"
  "A1","course","web_launch","00:00:00","","https://YYYYY/startSession","100","93","","","clientID=XXXXX",""`;


  return contents.replace('XXXXX', secret).replace('YYYYY', window.location.host);
}

/**
 *
 */
function getCrs() {
  const contents = `[Course]
    Course_Creator=Apex Innovations
    Course_ID=15
    Course_System=HTML
    Course_Title=NIHSS+
    Level=1
    Max_Fields_CST=2
    Total_AUs=1
    Total_Blocks=0
    Version=3.0
    [Course_Behavior]
    Max_Normal=1
    [Course_Description]
    This class is the National Institute of Health Stroke Scale (NIHSS) Training and Certification.`;

  return contents;
}

/**
 *
 */
function getCst() {
  const contents = `"block","member"
                    "ROOT","A1"`;
  return contents;
}

/**
 *
 */
function getDes() {
  const contents = `"system_id","developer_id","title","description"
                        "A1","1","Title","Description"`;

  return contents;
}

/**
 * @param name
 * @param secret
 */
const generateManifest = (name, secret) => {
  const zip = new JSZip();


  zip.file('course.au', getAu(secret));
  zip.file('course.crs', getCrs());
  zip.file('course.cst', getCst());
  zip.file('course.des', getDes());

  zip.generateAsync({type: 'blob'})
      .then((content)=> {
        saveAs(content, name.replace(' ', '') + '_NIHSSManifest.zip');
      });
};

export default generateManifest;
